import * as u from '@jsmanifest/utils'
import React from 'react'
import type { Draft } from 'immer'
import produce from 'immer'
import type { User } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import type { RouteComponentProps } from '@reach/router'
import { Router } from '@reach/router'
import { ClassNames } from '@emotion/react'
import {
  Box,
  GridItem,
  IconButton,
  Spinner,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'

import { auth0 as auth0Config } from 'app/config'
import Layout from 'components/Layout'
import DataLoader from 'admin/DataLoader'
import Portal from 'admin/Portal'
import Accounts from 'admin/Accounts'
import Events from 'admin/Events'
import Titles from 'admin/Titles'
import Volunteer from 'admin/Volunteer'
import type { SidebarProps } from 'admin/Sidebar'
import Sidebar from 'admin/Sidebar'
import useDeviceSizes from 'hooks/useDeviceSizes'
import { MenuIcon } from 'components/Icon'
import * as ls from 'utils/ls'
import { SIDEBAR_OPENED_STATE_KEY } from '../constants'

const isLoginPage = (location: any) => location.pathname.endsWith('/login')

const ProtectedRoute = <C extends React.ComponentType<any>>({
  component: Component,
  authed,
  location,
  navigate,
  ...rest
}: React.ComponentProps<C> &
  RouteComponentProps<any> & { authed: boolean | null; component: C }) => {
  if (authed === null) return null
  if (authed === false) {
    if (location.pathname !== '/admin/login') navigate?.('/admin/login')
    return null
  }
  return <Component location={location} navigate={navigate} {...rest} />
}

const initialState = {
  columns: 2,
}

function AdminPage({ location, navigate, ...props }: RouteComponentProps) {
  const [state, _setState] = React.useState(() => {
    const init = { ...initialState }
    const lastOpenState = ls.get(SIDEBAR_OPENED_STATE_KEY)
    if (u.isBool(lastOpenState)) {
      init.columns = lastOpenState ? 2 : 1
    }
    return init
  })
  const [authed, setAuthed] = React.useState<boolean | null>(null)
  const [user, setUser] = React.useState<User>(null)
  const auth0 = useAuth0()
  const [isMobile, isTablet, isDesktop, isWidescreen] = useDeviceSizes()
  const isMounted = React.useRef(false)

  const setState = React.useCallback(
    (fn: (draft: Draft<typeof initialState>) => void) => {
      _setState(produce(fn))
    },
    [],
  )

  React.useEffect(() => {
    isMounted.current = true
  }, [])

  React.useEffect(
    () => void ls.set(SIDEBAR_OPENED_STATE_KEY, state.columns > 1),
    [state.columns],
  )

  React.useEffect(() => {
    if (!auth0.isLoading) {
      if (auth0.isAuthenticated) {
        if (
          (!authed || !user) &&
          ['pfftdammitchris@gmail.com'].some(
            (email) => auth0.user?.email === email,
          )
        ) {
          setAuthed(true)
          setUser(auth0.user)
        }
      } else {
        setAuthed(false)
        auth0.loginWithRedirect({
          display: 'page',
          redirectUri: auth0Config.redirectUri,
        })
      }
    }
  }, [auth0, authed, user])

  React.useEffect(() => {
    if (u.isBool(authed)) {
      if (!authed) {
        if (!isLoginPage(location)) navigate('/admin/login')
      } else {
        if (isLoginPage(location)) navigate('/admin')
      }
    }
  }, [authed])

  React.useEffect(() => {
    console.log(`[AdminPage] User`, user)
  }, [user])

  const Login: React.FC<RouteComponentProps<any>> = React.memo(
    ({ children }) => <>{children || null}</>,
    () => true,
  )

  const sidebar = {
    items: [
      { label: 'Overview', to: '/' },
      { label: 'Accounts', to: '/accounts' },
      { label: 'Events', to: '/events' },
      { label: 'Titles', to: '/titles' },
      {
        label: 'Volunteer',
        to: '/volunteer',
        menu: [
          { label: 'Applied', to: '/volunteer/applied' },
          { label: 'Profiles', to: '/volunteer/profiles' },
        ],
      },
    ] as SidebarProps['items'],
  }

  const sidebarWidth = 300

  const openSidebar = React.useCallback(
    () => setState((d) => void (d.columns = 2)),
    [],
  )

  const closeSidebar = React.useCallback(
    () => setState((d) => void (d.columns = 1)),
    [],
  )

  const isCollapsed = state.columns < 2

  return (
    <>
      <DataLoader />
      <ClassNames>
        {({ css }) => (
          <Layout
            className={css({
              position: 'absolute',
              width: '100%',
              overflowY: 'auto',
            })}
          >
            {!u.isBool(authed) ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDir="column"
                w="100%"
                p={10}
              >
                <Spinner label="Authenticating..." size="xl" thickness="4px" />
                <Text mt={2} textAlign="center">
                  Authenticating...
                </Text>
              </Box>
            ) : (
              <SimpleGrid
                columns={state.columns}
                h="full"
                templateColumns={`${
                  isCollapsed ? '1fr' : `${sidebarWidth}px`
                } auto`}
                spacing="10px"
              >
                {!isCollapsed ? (
                  <GridItem bg="blackAlpha.400">
                    <Router basepath="/admin">
                      <ProtectedRoute<typeof Sidebar>
                        authed={authed}
                        component={Sidebar}
                        items={sidebar.items}
                        path="/"
                        onClose={closeSidebar}
                        default
                      />
                    </Router>
                  </GridItem>
                ) : null}
                <GridItem p={3}>
                  {isCollapsed ? (
                    <IconButton
                      aria-label="open-sidebar"
                      colorScheme="blue"
                      onClick={openSidebar}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : null}
                  <Router basepath="/admin">
                    <ProtectedRoute
                      authed={authed}
                      path="/accounts"
                      component={Accounts}
                    />
                    <ProtectedRoute
                      authed={authed}
                      path="/events"
                      // prettier-ignore
                      columns={isMobile ? 1 : isTablet ? 2 : isDesktop ? 3 : isWidescreen ? 4 : 3}
                      component={Events}
                    />
                    <Login path="/login" redirect default />
                    <ProtectedRoute
                      authed={authed}
                      path="/"
                      component={Portal}
                    />
                    <ProtectedRoute
                      authed={authed}
                      path="/titles"
                      component={Titles}
                      limit={150}
                    />
                    <ProtectedRoute
                      authed={authed}
                      path="/volunteer"
                      component={Volunteer}
                    />
                  </Router>
                </GridItem>
              </SimpleGrid>
            )}
          </Layout>
        )}
      </ClassNames>
    </>
  )
}

export default AdminPage
