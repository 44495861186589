import { createIcon } from '@chakra-ui/react'

export const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 512 512',
  path: [
    <path
      fill="currentColor"
      d="M495.304 278.262H294.957c-9.18 0-16.696 7.477-16.696 16.696v200.348c0 9.214 7.482 16.693 16.696 16.693h200.348c9.214 0 16.696-7.48 16.696-16.693V294.958c-.001-9.219-7.516-16.696-16.697-16.696z"
    />,
    <path
      fill="#32bbff"
      d="M217.043 278.262H16.696C7.515 278.262 0 285.739 0 294.958v200.348c0 9.214 7.482 16.693 16.696 16.693h200.348c9.214 0 16.696-7.48 16.696-16.693V294.958c-.001-9.219-7.516-16.696-16.697-16.696z"
    />,
    <path
      fill="currentColor"
      d="M16.696 511.999h200.348c9.214 0 16.696-7.482 16.696-16.693V294.958c0-4.606-1.877-8.776-4.9-11.796L4.895 507.106a16.643 16.643 0 0 0 11.801 4.893z"
    />,
    <path
      fill="#2ee6e6"
      d="M217.043.001H16.696C7.515.001 0 7.479 0 16.697v200.348c0 9.214 7.482 16.693 16.696 16.693h200.348c9.214 0 16.696-7.48 16.696-16.693V16.697C233.739 7.479 226.224.001 217.043.001z"
    />,
    <path
      fill="#32bbff"
      d="M495.304.001H294.957c-9.18 0-16.696 7.477-16.696 16.696v200.348c0 9.214 7.482 16.693 16.696 16.693h200.348c9.214 0 16.696-7.48 16.696-16.693V16.697C512 7.479 504.485.001 495.304.001z"
    />,
    <path
      fill="currentColor"
      d="M294.957 233.738h200.348c9.214 0 16.696-7.482 16.696-16.693V16.697c0-4.606-1.877-8.776-4.9-11.796L283.156 228.845a16.643 16.643 0 0 0 11.801 4.893z"
    />,
  ],
})
