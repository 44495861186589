import React from 'react'
import type { InputProps } from '@chakra-ui/react'
import type {
  Control,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import Box from 'components/Box'
import InputField from './InputField'
import NotesField from './NotesField'
import type useCRUDForm from './useCRUDForm'
import { CRUDFormProvider } from './useCRUDFormCtx'
import type * as t from './crudFormTypes'

export interface InputFieldGetValue<Item> {
  (item: Item): any
}

export interface CRUDFormFieldProps<Item> {
  id?: string
  helperText?: string
  label?: string
  name: string
  mode?: t.FieldMode
  placeholder?: string
  getValue?: InputFieldGetValue<Item>
  inputProps?: InputProps
}

export interface CRUDFormProps<S extends FieldValues, Item = any>
  extends Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'>,
    Pick<ReturnType<typeof useCRUDForm>, 'onSubmit' | 'setRef'> {
  children?: (opts: t.CRUDFormContext) => React.ReactNode
  control?: Control<S>
  fields?: CRUDFormFieldProps<Item>[]
  handleSubmit?: UseFormHandleSubmit<S>
  maxW?: number
  register?: UseFormRegister<S>
  values?: S
}

function CRUDForm({
  children,
  control,
  fields = [],
  handleSubmit,
  onSubmit,
  register,
  setRef,
  values,
  ...props
}: CRUDFormProps<any, any>) {
  const ctx: t.CRUDFormContext = React.useMemo(
    () => ({
      styles: {
        input: {
          bg: 'whiteAlpha.900',
          borderRadius: 4,
          borderColor: 'mediumpurple',
          colorScheme: 'blackAlpha',
          focusBorderColor: 'green.400',
          fontSize: 'xs',
          size: 'xs',
          variant: 'filled',
        },
      },
    }),
    [],
  )

  return (
    <CRUDFormProvider value={ctx}>
      <Box as="form" ref={setRef} onSubmit={handleSubmit(onSubmit)} {...props}>
        {fields?.map?.((fieldDescriptor, index) => {
          const {
            name: fieldName,
            id: fieldId,
            label: fieldLabel,
            mode: fieldMode,
            inputProps: inputPropsProp,
            helperText,
            placeholder,
          } = fieldDescriptor

          const fieldValue = values[fieldName]

          const inputProps = {
            id: fieldId,
            name: fieldName,
            size: 'xs',
            variant: 'filled',
            color: '#000',
            width: '100%',
            ...inputPropsProp,
          }

          const rhfProps = {
            control,
            defaultValue: fieldValue,
            helperText: helperText,
            name: fieldName,
            placeholder: placeholder,
          }

          return (
            <React.Fragment key={fieldId || `${fieldName}${index}`}>
              {fieldMode === 'notes' ? (
                <NotesField
                  inputProps={inputProps}
                  pendingInputProps={{
                    color: ctx?.styles?.input?.color,
                    variant: ctx?.styles?.input?.variant,
                  }}
                  {...rhfProps}
                />
              ) : (
                <InputField
                  label={fieldLabel}
                  type="text"
                  {...inputProps}
                  {...rhfProps}
                  {...register?.(fieldName)}
                />
              )}
            </React.Fragment>
          )
        }) || null}
        {children?.(ctx) || null}
      </Box>
    </CRUDFormProvider>
  )
}

export default CRUDForm
