import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import type { IGatsbyImageData } from 'gatsby-plugin-image'

export default function useMissingImage() {
  const query = useStaticQuery<{
    file: {
      childImageSharp: { gatsbyImageData: IGatsbyImageData }
    }
  }>(graphql`
    query MissingImageQuery {
      file(name: { eq: "no-image" }) {
        name
        childImageSharp {
          gatsbyImageData(
            formats: WEBP
            layout: FIXED
            width: 50
            aspectRatio: 1
          )
        }
      }
    }
  `)

  return query?.file?.childImageSharp?.gatsbyImageData
}
