import * as u from '@jsmanifest/utils'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { GatsbyImage } from 'gatsby-plugin-image'
import type { RouteComponentProps } from '@reach/router'
import {
  Badge,
  ButtonGroup,
  Button,
  Heading,
  Progress,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import formatDate from 'date-fns/format'
import Box from 'components/Box'
import useDeviceSizes from 'hooks/useDeviceSizes'
import useEventsImages from 'hooks/useEventsImages'
import useMissingImage from 'hooks/useMissingImage'
import type { EventsState } from './store'
import { eventsState as eventsRecoilState } from './store'
import * as c from '../constants'
import type { Api } from 'types'
import useCommonLoadData from 'hooks/useCommonLoadData'

export interface EventsPageProps {
  columns?: number
}

function renderText(maxLen = 200, key: string, value: any) {
  let Component = Text
  let props = { color: 'gray.600', fontSize: 'xs' } as any

  if (u.isStr(value)) {
    if (/date/i.test(key)) {
      value = formatDate(new Date(value), 'MM.dd.yyyy hh:mm:a')
    } else if (value.length > maxLen - 3) {
      value = value.substring(0, maxLen - 3).concat('...')
    }
    if (key === 'location') {
      if (value == 'Online') {
        Component = Badge
        props = { colorScheme: 'blue', userSelect: 'none', variant: 'outline' }
      }
    }
  } else if (u.isObj(value)) {
    if (value.latitude && value.longitude) {
      value = `lat: ${value.latitude}, lon: ${value.longitude}`
    } else {
      value = ''
    }
  } else {
    value = String(value)
  }

  return <Component {...props}>{value}</Component>
}

function getEventImage(
  name: string,
  eventImages: ReturnType<typeof useEventsImages>,
) {
  switch (name) {
    case c.ANAHEIM:
      return eventImages[c.ANAHEIM]
    case 'letsGoOutside':
      return eventImages[c.LETS_GO_OUTSIDE]
    case 'meetup':
      return eventImages[c.MEETUP]
    case 'pepperwoodPreserve':
      return eventImages[c.PEPPERWOOD_PRESERVE]
    case 'volunteerMatch':
      return eventImages[c.VOLUNTEER_MATCH]
    case 'volunteerNetworkOc':
      return eventImages[c.VOLUNTEER_NETWORK_OC]
    default:
      return eventImages[c.NO_IMAGE]
  }
}

function AdminEventsPage({
  columns = 2,
}: EventsPageProps & RouteComponentProps) {
  const eventImages = useEventsImages()
  const missingImageData = useMissingImage()
  const { fetching, items = [], error } = useRecoilValue(eventsState)
  const [isMobile, isTablet, isDesktop, isWideScreen] = useDeviceSizes()

  const [eventsState, setEventsState] = useCommonLoadData<
    EventsState,
    Api.EventsDataObject[]
  >(eventsRecoilState, {
    key: 'fetch-events',
    endpoint: '/api/events',
  })

  const staticImages = React.useMemo(
    () => ({ ...eventImages, 'no-image': missingImageData }),
    [eventImages, missingImageData],
  )

  return (
    <>
      {fetching ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Progress m="auto" />
        </Box>
      ) : error ? (
        <Text color="red.400">[error.name]: {error.message}</Text>
      ) : (
        <>
          <Heading fontSize="xl" my={5}>
            Events / Volunteering / Community Services
          </Heading>
          <SimpleGrid columns={columns} spacing={2}>
            {items?.map?.(
              ({
                id,
                title,
                description,
                startDate,
                name,
                location,
                url,
              }: Api.EventsDataObject) => (
                <Box
                  key={id}
                  mode="card"
                  display="flex"
                  flexDir="column"
                  justifyContent="center"
                  maxH={300}
                  overflow="hidden"
                  w="full"
                  textAlign="center"
                >
                  <Box display="flex" justifyContent="center" borderRadius={50}>
                    <GatsbyImage
                      alt={title}
                      image={getEventImage(name, staticImages)}
                    />
                  </Box>
                  <Box h="full" p={3}>
                    <Box
                      as="a"
                      href={url}
                      fontWeight="semibold"
                      lineHeight="tight"
                      target="_blank"
                    >
                      {title ? renderText(40, 'title', title) : null}
                    </Box>
                    <Box as="a" href={url} target="_blank" isTruncated>
                      {description
                        ? renderText(80, 'description', description)
                        : null}
                    </Box>
                    {/* <Badge borderRadius="full" px="2" colorScheme="teal">
                        {}
                      </Badge> */}

                    <Badge borderRadius="base" px="1">
                      {startDate ? renderText(50, 'startDate', startDate) : ''}
                    </Badge>
                    <Box mt="2">
                      <Text as="span" color="gray.600" fontSize="xs">
                        {location ? renderText(50, 'location', location) : null}
                      </Text>
                    </Box>
                  </Box>
                  <ButtonGroup>
                    <Button onClick={() => {}} colorScheme="blackAlpha">
                      Delete
                    </Button>
                  </ButtonGroup>
                </Box>
              ),
            )}
          </SimpleGrid>
        </>
      )}
    </>
  )
}

export default AdminEventsPage
