import { atom, selector } from 'recoil'
import type { Api } from 'types'

export interface AccountsState {
  error: Error | null
  fetching: boolean
  items: Api.AccountsDataObject[]
}

export const accountsState = atom<AccountsState>({
  key: 'accounts-state',
  default: {
    error: null,
    fetching: false,
    items: [],
  },
})

export interface EventsState {
  error: Error | null
  fetching: boolean
  items: Api.EventsDataObject[]
}

export const eventsState = atom<EventsState>({
  key: 'events-state',
  default: {
    error: null,
    fetching: false,
    items: [],
  },
})

export interface TitlesState {
  error: Error | null
  fetching: boolean
  items: string[]
}

export const titlesState = atom<TitlesState>({
  key: 'titles-state',
  default: {
    error: null,
    fetching: false,
    items: [],
  },
})

export interface VolunteerState {
  error: Error | null
  fetching: boolean
  items: {
    name: string
    url?: string
    notes?: { value: any }[]
    thumbnail?: string
  }[]
}

export const volunteerState = atom<VolunteerState>({
  key: 'volunteer-state',
  default: {
    error: null,
    fetching: false,
    items: [],
  },
})

export interface VolunteerAppliedState {
  error: Error | null
  fetching: boolean
  items: {
    name: string
    date: string
    url?: string
    notes?: { value: any }[]
    thumbnail?: string
  }[]
}

export const volunteerAppliedState = atom<VolunteerAppliedState>({
  key: 'volunteer-applied-state',
  default: {
    error: null,
    fetching: false,
    items: [],
  },
})
