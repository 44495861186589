import React from 'react'
import { Button, Heading, Text } from '@chakra-ui/react'
import Box from 'components/Box'

export interface CRUDConfirmDeleteProps<Item = any> {
  item: Item
  itemToString?: (item: Item) => string
  onCancelRemove?: React.MouseEventHandler<HTMLButtonElement>
  removeItem: (item: Item) => any
}

function CRUDConfirmDelete<Item = any>({
  item,
  itemToString,
  onCancelRemove,
  removeItem,
}: CRUDConfirmDeleteProps) {
  return (
    <Box
      position="fixed"
      display="flex"
      alignItems="center"
      justifyContent="center"
      top={0}
      right={0}
      bottom={0}
      left={0}
    >
      <Box mode="modal">
        <Heading color="whiteAlpha.800" fontSize="md">
          Are you sure you want to delete{' '}
          <Text color="cyan.400" display="inline">
            {itemToString(item as Item)}
          </Text>
          ?
        </Heading>
        <Box display="flex" justifyContent="center" p={2}>
          <Button colorScheme="blackAlpha" onClick={onCancelRemove}>
            No
          </Button>
          <span style={{ width: 6 }} />
          <Button
            onClick={() => removeItem(item as Item)}
            colorScheme="whiteAlpha"
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(CRUDConfirmDelete, () => true)
