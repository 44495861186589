export function get(key: string): any {
  if (typeof window === 'undefined') return
  let value = localStorage.getItem(key)
  if (typeof value === 'string') {
    try {
      value = JSON.parse(value)
    } catch {}
  }
  return value
}

export function set(key: string, value: any) {
  if (typeof window === 'undefined') return
  if (typeof value !== 'string') {
    value = String(value)
  }
  localStorage.setItem(key, value)
}

export function clear() {
  if (typeof window === 'undefined') return
  localStorage.clear()
}
