import * as u from '@jsmanifest/utils'
import React from 'react'
import produce from 'immer'
import { useRecoilState } from 'recoil'
import type { RecoilState } from 'recoil'
import useSWR from 'swr'

/**
 * @param swrState Initial state. Must implement the interface: { fetching: boolean; fetched: boolean; error: null | Error } & Record<string, any>
 * @param param1 Options
 * @returns
 */
function useCommonLoadData<
  State extends Record<string, any> = Record<string, any>,
  Result = any,
>(
  swrState: RecoilState<State>,
  {
    endpoint = '',
    key = u.getRandomKey(),
    mergeSuccessProps,
    mergeErrorProps,
  }: {
    endpoint?: string
    key?: string
    mergeSuccessProps?: (data: Result, state: State) => any
    mergeErrorProps?: (error: Error, state: State) => any
  } = {},
) {
  const [state, setState] = useRecoilState(swrState)

  useSWR<Result>(key, {
    fetcher: async () => {
      setState(produce((d) => void (d.fetching = true)))
      return (await fetch(endpoint)).json()
    },
    onSuccess: (data) =>
      setState(
        produce((draft) =>
          u.assign(
            draft,
            u.isFnc(mergeSuccessProps)
              ? { fetching: false, ...mergeSuccessProps(data, draft) }
              : { items: data, fetching: false },
          ),
        ),
      ),
    onError: (error) =>
      setState(
        produce((draft) => {
          const err = error instanceof Error ? error : new Error(String(error))
          u.assign(
            draft,
            u.isFnc(mergeErrorProps)
              ? { fetching: false, ...mergeErrorProps(err, draft) }
              : { error: err, fetching: false },
          )
        }),
      ),
    revalidateOnFocus: false,
  })

  return [state, setState] as const
}

export default useCommonLoadData
