import type { LiteralUnion } from 'type-fest'
import * as u from '@jsmanifest/utils'
import React from 'react'
import Downshift from 'downshift'
import type {
  ControllerStateAndHelpers,
  DownshiftProps,
  DownshiftState,
  StateChangeOptions,
} from 'downshift'
import { CircularProgress, Text } from '@chakra-ui/react'
import Box from 'components/Box'
import Svg from 'components/svg'

export interface AutoCompleteInputProps<Item = any>
  extends Omit<DownshiftProps<Item>, 'children'> {
  descriptionKey?: string
  children?: (
    opts: ControllerStateAndHelpers<Item> & {
      listItems: React.ReactNode[]
    },
  ) => React.ReactNode
  components?: {
    ul?: React.ElementType
    li?: React.ElementType
    input?: React.ElementType
  }
  idKey?: LiteralUnion<'id', string>
  items?: Item[]
  loading?: boolean
  limit?: number
  mode?: 'default' | 'selection'
  onSelect?: (
    item: Item,
    options: ControllerStateAndHelpers<Item> & {
      mode: AutoCompleteInputProps<Item>['mode']
    },
  ) => void
  renderItem?: (
    item: Item,
    downshiftProps: ControllerStateAndHelpers<Item>,
  ) => React.ReactNode
  selectedIds?: string[]
  uppercase?: boolean
  value?: string
}

const defaultRenderItem = (
  item: any = {},
  downshiftProps: ControllerStateAndHelpers<any>,
  opts: Pick<
    AutoCompleteInputProps<any>,
    'components' | 'descriptionKey' | 'idKey' | 'mode' | 'selectedIds'
  > & {
    index: number
    uppercase: boolean
  },
) => {
  const descriptionKey = opts.descriptionKey
  const selectedIds = opts.selectedIds
  const stringifiedItem = downshiftProps.itemToString(item) || ''
  const uppercasedStringifiedItem = stringifiedItem?.toUpperCase?.() || ''
  const id = item?.[opts?.idKey]
  const isSelected = selectedIds.includes(id)
  const isShowingDescription = !!(descriptionKey && item[descriptionKey])
  const mode = opts.mode
  const isSelectionHighlight = mode === 'selection' && isSelected

  return (
    <React.Fragment key={id}>
      {React.createElement(
        opts.components.li,
        {
          ...downshiftProps.getItemProps({
            style: {
              color:
                downshiftProps.highlightedIndex === opts.index ? 'aqua' : null,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              fontWeight: isSelectionHighlight ? 'bold' : 'normal',
              paddingTop: 0,
              paddingBottom: 0,
            },
            index: opts.index,
            item,
          }),
        },
        isSelectionHighlight ? (
          <Svg key={`${id}.svg`} stroke="cyan" icon="checkboxChecked" />
        ) : null,
        isSelectionHighlight ? (
          <Box
            key={`${id}.selection-icon`}
            display="inline-block"
            style={{ width: 25 }}
          />
        ) : null,
        opts.uppercase
          ? uppercasedStringifiedItem
          : downshiftProps.itemToString(item),
      )}
      {(isShowingDescription && (
        <Box
          as={Text}
          color="whiteAlpha.800"
          fontSize="x-small"
          ml={3}
          {...(isSelectionHighlight
            ? {
                position: 'relative',
                left: 23,
              }
            : undefined)}
        >
          {item[descriptionKey]}
        </Box>
      )) ||
        null}
    </React.Fragment>
  )
}

const AutoCompleteInput = React.forwardRef(function AutoCompleteInput<Item>(
  {
    children,
    components: componentsProp,
    descriptionKey,
    idKey = 'id',
    items = [],
    loading,
    limit = 150,
    itemToString,
    mode = 'default',
    onInputValueChange: onInputValueChangeProp,
    onSelect: onSelectProp,
    renderItem,
    selectedIds = [],
    uppercase = false,
    value: valueProp,
    ...props
  }: React.PropsWithChildren<AutoCompleteInputProps<Item>>,
  inputRef: React.MutableRefObject<HTMLInputElement>,
) {
  const { current: isControlled } = React.useRef(!u.isUnd(valueProp))
  const [value, setValue] = React.useState(valueProp || '')

  const stateReducer = React.useCallback(
    (state: DownshiftState<Item>, changes: StateChangeOptions<Item>) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.blurInput:
          return { ...changes, inputValue: state.inputValue }
        case Downshift.stateChangeTypes.clickItem:
          const newState = { ...changes, inputValue: value }
          inputRef?.current && (inputRef.current.value = state.inputValue || '')
          return newState
        case Downshift.stateChangeTypes.mouseUp:
          return { ...changes, inputValue: state.inputValue }
        default:
          return changes
      }
    },
    [inputRef, value],
  )

  const components = {
    ul: componentsProp?.ul || 'ul',
    li: componentsProp?.li || 'li',
    input: componentsProp?.input || 'input',
  }

  const onInputValueChange: DownshiftProps<Item>['onInputValueChange'] =
    React.useCallback(
      (val, stateAndHelpers) => {
        if (onInputValueChangeProp) {
          onInputValueChangeProp(val, stateAndHelpers)
        } else {
          setValue(val)
        }
      },
      [onInputValueChangeProp],
    )

  const onSelect = React.useCallback(
    (item: Item, stateAndHelpers: ControllerStateAndHelpers<Item>) => {
      onSelectProp?.(item, { ...stateAndHelpers, mode })
    },
    [mode, onSelectProp],
  )

  return (
    <Downshift
      getItemId={(index) => items[index]?.[idKey]}
      stateReducer={stateReducer}
      inputValue={isControlled ? valueProp : value}
      itemToString={itemToString}
      onInputValueChange={onInputValueChange}
      onSelect={onSelect}
      {...props}
    >
      {(downshiftProps) => {
        const { inputValue, isOpen, getMenuProps } = downshiftProps

        const listItems = isOpen
          ? u.reduce(
              items,
              (acc, item: Item, index) => {
                if (acc.length < limit) {
                  const uppercasedStringifiedItem =
                    itemToString(item)?.toUpperCase() || ''
                  const formattedValue = inputValue?.toUpperCase?.() || ''
                  if (
                    !inputValue ||
                    uppercasedStringifiedItem.includes(formattedValue)
                  ) {
                    return acc.concat(
                      (renderItem || defaultRenderItem)(item, downshiftProps, {
                        components,
                        descriptionKey,
                        idKey,
                        index,
                        mode,
                        selectedIds,
                        uppercase,
                      }),
                    )
                  }
                }
                return acc
              },
              [] as React.ReactNode[],
            )
          : null

        return React.createElement(
          components.li,
          getMenuProps({
            refKey: 'ref',
            style: { listStyle: 'none' },
          }),
          loading ? (
            <Box display="flex" alignItems="center" mb={1}>
              <CircularProgress
                color="cyan"
                size={25}
                thickness={10}
                isIndeterminate
              />
              <Text fontWeight="bold" ml={2} color="cyan.300">
                Loading...
              </Text>
            </Box>
          ) : null,
          <Box
            opacity={loading ? 0.4 : 1}
            pointerEvents={loading ? 'none' : 'auto'}
            userSelect={loading ? 'none' : 'auto'}
          >
            {children
              ? children({
                  listItems,
                  ...downshiftProps,
                })
              : listItems}
          </Box>,
        )
      }}
    </Downshift>
  )
})

export default AutoCompleteInput
