import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import type {
  LETS_GO_OUTSIDE,
  MEETUP,
  PEPPERWOOD_PRESERVE,
  VOLUNTEER_MATCH,
  VOLUNTEER_NETWORK_OC,
} from '../constants'

export type EventName =
  | typeof LETS_GO_OUTSIDE
  | typeof MEETUP
  | typeof PEPPERWOOD_PRESERVE
  | typeof VOLUNTEER_MATCH
  | typeof VOLUNTEER_NETWORK_OC

export default function useEventsImages(): Record<EventName, IGatsbyImageData> {
  const query = useStaticQuery<{
    allFile: {
      nodes: {
        childImageSharp: { gatsbyImageData: IGatsbyImageData }
        name: string
      }[]
    }
  }>(graphql`
    query EventImagesQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "events" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              formats: WEBP
              layout: FIXED
              width: 50
              aspectRatio: 1
            )
          }
          name
        }
      }
    }
  `)
  return (query?.allFile?.nodes || []).reduce((acc, node) => {
    acc[node.name] = node.childImageSharp?.gatsbyImageData
    return acc
  }, {} as ReturnType<typeof useEventsImages>)
}
