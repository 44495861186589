import type React from 'react'
import type { LiteralUnion } from 'type-fest'
import type { BoxProps, InputProps } from '@chakra-ui/react'
import type { UseFormReturn } from 'react-hook-form'

export type FieldMode = 'notes' | 'text'

export interface FieldObjectBase<N extends string = string> {
  mode?: FieldMode
  name: LiteralUnion<N, string>
  [key: string]: any
}

export interface FieldInputObject
  extends FieldObjectBase,
    Omit<InputProps, 'name'> {
  mode: 'text'
}

export type CRUDFormRef = React.MutableRefObject<{
  form: UseFormReturn<any, any>
  el: HTMLFormElement
}>

export type CRUDFormContext = {
  /**
   * Common styles
   */
  styles?: {
    field?: Partial<BoxProps>
    input?: Partial<InputProps>
  }
}

export interface GetItemId<Item = any> {
  (item: Item): any
}

export namespace Notes {
  export type Value = number | string

  export interface Item {
    value: Notes.Value
    [key: string]: any
  }
}
