import React from 'react'
import type { InputProps as ChakraInputProps } from '@chakra-ui/react'

export interface CRUDPageInputProps extends ChakraInputProps {
  component: React.ComponentType<any>
}

const CRUDPageInput: React.FC<CRUDPageInputProps> =
  React.forwardRef<CRUDPageInputProps>(
    ({ component: Component, ...rest }: CRUDPageInputProps, ref) => {
      return <Component ref={ref} {...rest} />
    },
  )

export default React.memo(CRUDPageInput, (p, np) => p.value === np.value)
