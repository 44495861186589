import * as u from '@jsmanifest/utils'
import type { AxiosError } from 'axios'
import axios from 'axios'
import React from 'react'
import produce from 'immer'
import { css } from '@emotion/react'
import { Button, Input, List, ListItem, Modal, Text } from '@chakra-ui/react'
import Downshift from 'downshift'
import type { StateChangeOptions, DownshiftState } from 'downshift'
import type { RecoilState } from 'recoil'
import Box from 'components/Box'
import useCommonLoadData from 'hooks/useCommonLoadData'
import CRUDPageList from './List'
import CRUDPageInput from './Input'
import CRUDConfirmDelete from './ConfirmDelete'
import CRUDSecondaryValueFields from './SecondaryValueFields/SecondaryValueFields'
import { useSecondaryFields } from './SecondaryValueFields'

export interface ErrorResults {
  code?: number | string
  name: string
  message: string
  response?: any
  stack?: string
}

function isButtonSubmit(evt: any): evt is React.FormEvent<HTMLFormElement> {
  return 'elements' in evt.target
}

export interface CRUDPageProps<
  S extends Record<string, any>,
  Item extends S['items'][number] = S['items'][number],
> {
  /** Required */
  initialState: RecoilState<S>
  endpoint: string
  getRemoveItemParams: (item: Item) => any
  getInputValueAfterSubmit?: (inputValue: string) => string
  getInputValueAfterRemove?: (inputValue: string) => string
  getInputValueAfterRejectRemove?: (
    inputValue: string,
    opts: { event: React.MouseEvent<HTMLButtonElement> },
  ) => string
  idKey: string
  /** Optional */
  components?: {
    form?: React.ComponentType<any> | string
    input?: React.ComponentType<any> | string
    li?: React.ComponentType<any> | string
    ul?: React.ComponentType<any> | string
  }
  fetchKey?: string
  formName?: string
  getAddItemPostBody?: (item: Item) => any
  limit?: number
  itemToString?: (item: Item) => string
  isEqual?: (item1: Item, item2: Item) => boolean
  mergeSuccessProps?: (data: S['items']) => any
  onMount?: (state: S) => void
  onError?: (error: ErrorResults) => void
  secondaryFields?: SecondaryValueFieldsProps['descriptors']
  uppercase?: boolean
}

function getErrorMessages(error: AxiosError | Error | string) {
  const err = error instanceof Error ? error : new Error(String(error))
  const results = {} as ErrorResults
  if (axios.isAxiosError(err)) {
    if ('code' in err) results.code = err.code
    if (err.response) results.response = err.response
  }
  if (err.name) results.name = err.name
  if (err.message) results.message = err.message
  if (err.stack) results.stack = err.stack
  return results
}

function getKey<Item extends Record<string, any>>(item: Item, index = 0) {
  return u.isObj(item)
    ? item.title || item.id || item.key || index || u.getRandomKey()
    : String(item)
}

function createFindIndex<Item = any>(
  comparer: (item: Item, value: any) => boolean,
) {
  return (items: Item[], value: any) =>
    items.findIndex((item) => comparer(item, value))
}

function CRUDPage<
  S extends Record<string, any> = Record<string, any>,
  Item extends S['items'][number] = S['items'][number],
>({
  components,
  endpoint,
  idKey = 'id',
  fetchKey = `fetch:${endpoint}`,
  secondaryFields: secondaryFieldsProp = [],
  formName,
  getInputValueAfterSubmit = (x) => x,
  getInputValueAfterRemove,
  getInputValueAfterRejectRemove,
  getRemoveItemParams,
  initialState: initialDataState,
  limit = 50,
  isEqual = (item1, item2) =>
    u.isObj(item1) ? item1[idKey] === item2[idKey] : item1 === item2,
  mergeSuccessProps,
  onMount,
  getAddItemPostBody = (item: Item) => item,
  onError: onErrorProp,
  itemToString = (item: any) =>
    u.isObj(item) ? String(item[idKey]) : String(item),
  uppercase = true,
}: CRUDPageProps<S, Item>) {
  const [opened, setOpened] = React.useState(false)
  const [value, setValue] = React.useState('')

  const [itemSelectedToDelete, setItemSelectedToDelete] = React.useState(
    '' as Item | '',
  )

  const [state, setState] = useCommonLoadData(initialDataState, {
    key: fetchKey,
    endpoint,
    mergeSuccessProps,
  })

  const { values: secondaryFields, onChange: onSecondaryValueChange } =
    useSecondaryFields({ descriptors: secondaryFieldsProp })

  const inputRef = React.useRef<HTMLInputElement>()
  const prevValueRef = React.useRef<string>('')
  const findIndex = React.useMemo(() => createFindIndex(isEqual), [])

  const FormComponent = components?.form || 'form'
  const InputComponent = components?.input || Input
  const ListComponent = components?.ul || List
  const ListItemComponent = components?.li || ListItem

  const addItem = React.useCallback(async (item: Item) => {
    setState(produce((d) => void d.items.unshift(item)))
    try {
      await axios.post(endpoint, getAddItemPostBody(item))
    } catch (error) {
      setState(produce((d) => void d.items.splice(findIndex(d.items, item), 1)))
      onErrorProp?.(
        getErrorMessages(
          error instanceof Error ? error : new Error(String(error)),
        ),
      )
    }
  }, [])

  const removeItem = React.useCallback(
    async (item: Item) => {
      const currentValue = value
      setState(produce((d) => void d.items.splice(findIndex(d.items, item), 1)))
      setValue(getInputValueAfterRemove ? getInputValueAfterRemove(value) : '')
      try {
        await axios.delete(endpoint, { params: getRemoveItemParams(item) })
        setItemSelectedToDelete('')
      } catch (error) {
        setState(produce((d) => void d.items.unshift(item)))
        setValue(currentValue)
        onErrorProp?.(
          getErrorMessages(
            error instanceof Error ? error : new Error(String(error)),
          ),
        )
      }
    },
    [value, setValue],
  )

  const onSubmit = React.useCallback(async (evt: React.FormEvent) => {
    evt.preventDefault()

    let idValue = ''
    let inputEl: HTMLInputElement | null = null

    if (isButtonSubmit(evt)) {
      inputEl = Array.from(evt.currentTarget.elements).find(
        (el) => 'value' in el,
      ) as HTMLInputElement
      idValue = inputEl.value
    } else {
      idValue = (evt.target as any).value
    }

    setValue(getInputValueAfterSubmit(idValue))

    const item = {
      id: u.getRandomKey(),
      [idKey]: idValue,
    } as Item

    await addItem(item)
  }, [])

  const onCancelRemove = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setItemSelectedToDelete('')
      inputRef.current?.focus()
      console.log(prevValueRef.current)
      debugger
      if (getInputValueAfterRejectRemove) {
        setValue(getInputValueAfterRejectRemove(value, { event }))
      }
    },
    [value],
  )

  React.useEffect(() => void (!opened && setValue('')), [opened])
  React.useEffect(() => void onMount?.(state), [state])
  React.useEffect(() => {
    prevValueRef.current = inputRef.current?.value || ''
  }, [value])

  const stateReducer = React.useCallback(
    (state: DownshiftState<Item>, changes: StateChangeOptions<Item>) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.blurInput:
          return { ...changes, inputValue: state.inputValue }
        case Downshift.stateChangeTypes.clickItem:
          inputRef.current && (inputRef.current.value = state.inputValue || '')
          return { ...changes, inputValue: state.inputValue }
        case Downshift.stateChangeTypes.mouseUp:
          return { ...changes, inputValue: state.inputValue }
        default:
          return changes
      }
    },
    [],
  )

  const modalOpened = !!itemSelectedToDelete

  return (
    <>
      <CRUDSecondaryValueFields
        descriptors={secondaryFieldsProp}
        fields={secondaryFields}
        onChange={onSecondaryValueChange}
      />
      <Box my={10} />
      <Downshift
        itemToString={itemToString}
        onInputValueChange={(inputValue) => setValue(inputValue || '')}
        onSelect={setItemSelectedToDelete}
        stateReducer={stateReducer}
        inputValue={value}
        isOpen
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          selectedItem,
          getRootProps,
        }) => {
          const listItems = isOpen
            ? u.reduce(
                u.array(state.items),
                (acc, item: Item, index) => {
                  if (acc.length < limit) {
                    const stringifiedItem =
                      itemToString(item)?.toUpperCase() || ''
                    const formattedValue = inputValue.toUpperCase()
                    if (
                      !inputValue ||
                      stringifiedItem.includes(formattedValue)
                    ) {
                      return acc.concat(
                        <ListItemComponent
                          {...getItemProps({ index, item })}
                          css={css({
                            fontWeight:
                              selectedItem === stringifiedItem
                                ? 'bold'
                                : 'normal',
                            paddingTop: 0,
                            paddingBottom: 0,
                          })}
                          item={item}
                        >
                          {uppercase ? stringifiedItem : itemToString(item)}
                        </ListItemComponent>,
                      )
                    }
                  }
                  return acc
                },
                [] as React.ReactElement[],
              )
            : null

          const totalListItems = listItems?.length || 0
          const totalTitles = state.items.length
          const ratio = `${totalListItems} / ${totalTitles}`

          return (
            <FormComponent name={formName} onSubmit={onSubmit}>
              <Box display="flex">
                <Text>{ratio}</Text>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                my="10px"
                {...getRootProps({ refKey: 'ref' }, { suppressRefError: true })}
              >
                <CRUDPageInput
                  component={InputComponent}
                  ref={inputRef}
                  placeholder="Search/Add item"
                  size="sm"
                  variant="flushed"
                  {...getInputProps()}
                />
                <span style={{ width: 10 }} />
                <Button
                  type="submit"
                  size="sm"
                  colorScheme="blackAlpha"
                  variant="solid"
                >
                  Save
                </Button>
              </Box>
              <CRUDPageList
                component={ListComponent}
                {...getMenuProps({
                  refKey: 'componentRef',
                  style: { listStyle: 'none' },
                })}
              >
                {listItems}
              </CRUDPageList>
            </FormComponent>
          )
        }}
      </Downshift>
      <Modal isOpen={modalOpened} onClose={() => setOpened(false)}>
        {itemSelectedToDelete ? (
          <CRUDConfirmDelete
            item={itemSelectedToDelete}
            inputRef={inputRef}
            onCancelRemove={onCancelRemove}
            removeItem={removeItem}
            setItemSelectedToDelete={setItemSelectedToDelete}
            setValue={setValue}
            itemToString={itemToString}
          />
        ) : null}
      </Modal>
    </>
  )
}

export default CRUDPage
