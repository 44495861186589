import React from 'react'

export interface CRUDPageListProps {
  component: React.ComponentType<any>
  componentRef?: React.RefObject<any>
  ref?: any
}

function CRUDPageList({
  component: Component,
  componentRef,
  children,
  ...rest
}: React.PropsWithChildren<CRUDPageListProps>) {
  return (
    <Component {...rest} ref={componentRef}>
      {children}
    </Component>
  )
}

export default CRUDPageList
