import React from 'react'
import { ListItem, Text, useToast } from '@chakra-ui/react'
import type { AccountsState } from 'admin/store'
import { accountsState } from 'admin/store'
import CRUDPage from 'components/CRUDPage'
import Box from 'components/Box'

const ListComponent = ({
  // Children === item.service
  children,
  item,
  ...rest
}: React.PropsWithChildren<{ item: AccountsState['items'][number] }>) => {
  return (
    <Box
      as={ListItem}
      mode="card"
      color="blackAlpha.800"
      fontSize="sm"
      mb={1}
      minH={35}
      maxH={160}
      p={3}
      display="flex"
      alignItems="center"
      flexDir="column"
      {...rest}
    >
      {children}
      <Text>{item.email}</Text>
      <Text>{item.username}</Text>
      <Text>{item.password}</Text>
      <Text>{item.date}</Text>
    </Box>
  )
}

function AdminAccountsPage() {
  const toast = useToast()

  return (
    <CRUDPage
      idKey="service"
      components={{
        li: ListComponent,
      }}
      endpoint="/api/accounts"
      fetchKey="fetch-accounts"
      getAddItemPostBody={(item) => item}
      getRemoveItemParams={(item) => ({ id: item.id, service: item.service })}
      initialState={accountsState}
      itemToString={(account) => account?.service || ''}
      isEqual={(item, value) => !!(item.id && item.id === value?.id)}
      onMount={(state) => console.log(`[Accounts] State`, state)}
      onError={({ name, message, response }) => {
        toast({
          title: name,
          description: `${message}${response ? JSON.stringify(response) : ''}`,
          position: 'bottom',
          status: 'error',
        })
      }}
      uppercase={false}
    />
  )
}

export default AdminAccountsPage
