import * as u from '@jsmanifest/utils'
import React from 'react'
import type { BoxProps, InputProps, FormLabelProps } from '@chakra-ui/react'
import {
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react'
import type { Control, FieldValues, FieldPath } from 'react-hook-form'
import useCRUDFormCtx from './useCRUDFormCtx'

export interface InputFieldProps<
  S extends FieldValues = FieldValues,
  N extends FieldPath<S> = FieldPath<S>,
> extends Omit<InputProps, 'onChange'> {
  name: N
  boxProps?: BoxProps
  control: Control<any>
  errMsg?: string
  helperText?:
    | string
    | {
        value: string
        fontSize?: FormLabelProps['fontSize']
      }
  label?: string
  onChange?: (
    evt: React.ChangeEvent<HTMLInputElement>,
    value: string,
    name: string,
  ) => void
}

function renderHelperText(
  helperText:
    | string
    | {
        value: string
        fontSize?: FormLabelProps['fontSize']
      },
) {
  if (!helperText) return null
  let value = ''
  let fontSize = 'sm' as any
  if (u.isStr(helperText)) {
    value = helperText
  } else {
    value = helperText.value
    fontSize = helperText.fontSize || fontSize
  }
  return (
    <FormHelperText color="aqua" fontSize={fontSize}>
      {value}
    </FormHelperText>
  )
}

const InputField = React.forwardRef(
  <S extends FieldValues = FieldValues, N extends FieldPath<S> = FieldPath<S>>(
    {
      boxProps,
      control,
      errMsg,
      helperText,
      isRequired,
      label,
      name,
      onChange: onChangeProp,
      ...inputProps
    }: React.PropsWithChildren<InputFieldProps<S, N>>,
    ref: React.MutableRefObject<HTMLInputElement>,
  ) => {
    const { styles: ctxStyles } = useCRUDFormCtx()
    const size = inputProps.size || 'xs'
    const labelSize =
      size === 'xs'
        ? 'xx-small'
        : size === 'sm'
        ? 'xs'
        : size === 'md'
        ? 'sm'
        : size === 'lg'
        ? 'md'
        : 'sm'

    return (
      <Box overflow="hidden" width="full" {...boxProps}>
        <FormControl {...ctxStyles?.field} isRequired={isRequired}>
          {label ? (
            <FormLabel htmlFor={name} fontSize={labelSize}>
              {label}
            </FormLabel>
          ) : null}
          <Input
            ref={ref}
            isRequired={isRequired}
            name={name}
            size={size}
            {...inputProps}
            {...control?.register?.(name)}
          />
          {errMsg ? <FormErrorMessage>{errMsg}</FormErrorMessage> : null}
          {renderHelperText(helperText)}
        </FormControl>
      </Box>
    )
  },
)

export default InputField
