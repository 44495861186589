import * as u from '@jsmanifest/utils'
import React from 'react'
import partialRight from 'lodash/partialRight'
import { Box, Input } from '@chakra-ui/react'
import CRUDNotesInput from './NotesInput'
import type { SecondaryValueFieldsProps } from '../types'

function SecondaryValueFields({
  descriptors = [],
  fields,
  onChange: onChangeProp,
}: React.PropsWithChildren<SecondaryValueFieldsProps>) {
  return (
    <Box>
      {descriptors?.map?.((descriptor, index) => {
        const name = 'name' in descriptor ? descriptor.name : descriptor
        const value = fields[descriptor.name as string]
        return (
          <React.Fragment key={descriptor.id || index}>
            {descriptor.mode === 'notes' ? (
              <CRUDNotesInput
                inputProps={descriptor}
                onChange={
                  onChangeProp
                    ? partialRight(onChangeProp, descriptor)
                    : undefined
                }
                value={value}
              />
            ) : (
              <Input
                {...descriptor}
                key={descriptor.id}
                size="sm"
                value={!u.isStr(value) ? String(value) : value}
                variant="flushed"
                onChange={
                  onChangeProp
                    ? partialRight(onChangeProp, descriptor)
                    : undefined
                }
              />
            )}
          </React.Fragment>
        )
      }) || null}
    </Box>
  )
}

export default SecondaryValueFields
