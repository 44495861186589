import React from 'react'
import { Link } from 'gatsby'
import { Box, CloseButton, Input, List, ListItem, Text } from '@chakra-ui/react'
import type { ListItemProps } from '@chakra-ui/react'

export interface SidebarItemProps extends ListItemProps {
  label: string
  to?: string
}

export interface SidebarProps {
  items?: SidebarItemProps[]
  onClose?: () => void
}

export function SidebarItem({ label, to, ...rest }: SidebarItemProps) {
  if (!to) to = '#'
  else {
    if (!/^\/*admin/i.test(to)) {
      to = '/admin' + (to.startsWith('/') ? to : `/${to}`)
    }
  }
  return (
    <ListItem as={Link} scrollBehavior="smooth" to={to} {...rest}>
      {/* <ListIcon /> */}
      <Text>{label}</Text>
    </ListItem>
  )
}

function Sidebar({ items = [], onClose }: SidebarProps) {
  return (
    <Box position="fixed">
      <Box display="flex" justifyContent="space-between" p={3}>
        <Box>
          <Input
            w="fit-content"
            size="sm"
            placeholder="Type here..."
            disabled
          />
        </Box>
        <Box>
          <CloseButton onClick={onClose} title="Close sidebar" />
        </Box>
      </Box>
      <Box p={3}>
        {items.length ? (
          <List>
            {items.map((item) => (
              <SidebarItem key={item.to || item.label} {...item} />
            ))}
          </List>
        ) : null}
      </Box>
    </Box>
  )
}

export default Sidebar
