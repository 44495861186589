import * as u from '@jsmanifest/utils'
import type { Draft } from 'immer'
import produce from 'immer'
import omit from 'lodash/omit'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import React from 'react'
import type { LiteralUnion } from 'type-fest'
import type {
  FieldValues,
  NestedValue,
  UnpackNestedValue,
} from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { SetterOrUpdater } from 'recoil'
import type * as t from './crudFormTypes'

export interface StateObject<Item = any> {
  error: Error | null
  fetching: boolean
  items: Item[]
  [key: string]: any
}

const _findIndex = (idKey: string, items: any[], item: any) =>
  items.findIndex((obj) => obj[idKey] === item[idKey])

const _removeItem = (idKey: string, items: any[], item: any) => {
  const index = _findIndex(idKey, items, item)
  if (index > -1) items.splice(index, 1)
}

function getInitialFormValues<
  FormValues extends FieldValues,
  K extends keyof FormValues,
>(formValues: FormValues, fieldObjects: t.FieldObjectBase<string>[]) {
  return fieldObjects.reduce((acc, fieldObject) => {
    if (formValues[fieldObject.name]) {
      acc[fieldObject.name] = formValues[fieldObject.name]
    } else {
      acc[fieldObject.name] = fieldObject.mode === 'notes' ? [] : ''
    }
    return acc
  }, {} as Record<K, FormValues[K]>)
}

function useCRUDForm<
  IdKey extends keyof Item,
  FormValues extends FieldValues,
  Item extends State['items'][number],
  State extends StateObject<FormValues>,
>({
  endpoint,
  fields: fieldObjects,
  idKey,
  initialValues,
  items,
  defaultRequestOptions,
  getItemId = (item) => (u.isStr(item) ? item : item?.[idKey]),
  onRemoveItem,
  setState: setStateProp,
}: {
  defaultRequestOptions?: AxiosRequestConfig
  endpoint?: string
  fields: t.FieldObjectBase<IdKey>[]
  getItemId?: t.GetItemId<Item | Item[IdKey]>
  idKey?: IdKey
  initialValues?: Partial<State['items'][number] & { items?: Item[] }>
  itemToString?: (item: Item | Item[IdKey]) => string
  onRemoveItem?: (options: {
    items: (Item | Item[IdKey])[]
    getItemId: t.GetItemId<Item | Item[IdKey]>
  }) => void
  setState?: SetterOrUpdater<State>
}) {
  const crudRef = React.useRef<t.CRUDFormRef['current']>()
  const formRef = React.useRef<HTMLFormElement>()

  const defaultValue = React.useMemo(
    () => ({
      formValues: getInitialFormValues(
        omit(initialValues, 'items'),
        fieldObjects,
      ),
      items: initialValues.items,
    }),
    // eslint-disable-next-line
    [initialValues],
  )

  const form = useForm<{
    formValues: State['items'][number]
    items: Item[]
  }>({
    defaultValues: defaultValue,
  })

  const req = React.useRef(axios.create(defaultRequestOptions))

  const setState = React.useCallback(
    (fn: (draft: Draft<StateObject>) => void) => setStateProp?.(produce(fn)),
    [setStateProp],
  )

  const addItem = React.useCallback(
    async function onAddItem(item: Item) {
      try {
        setState((draft) => void draft?.items?.push(item))
        await req.current.post(endpoint, item)
        form.reset({
          formValues: getInitialFormValues({}, fieldObjects),
          items: [],
        })
      } catch (error) {
        setState((draft) => _removeItem(idKey, draft.items, item))
        const err = error instanceof Error ? error : new Error(String(error))
        throw err
      }
    },
    [endpoint, fieldObjects, form, idKey, setState],
  )

  const removeItem = React.useCallback(
    async (item: Item | Item[] | string[] | string) => {
      try {
        const items = u.array(item)
        setState((draft) =>
          items.forEach((obj) => _removeItem(idKey, draft.items, obj)),
        )
        await axios.delete(endpoint, {
          data: { idKey, id: items.map((obj) => getItemId(obj)) },
        })
        onRemoveItem?.({ getItemId, items })
      } catch (error) {
        setState((draft) =>
          u.array(item).forEach((obj) => draft.items.unshift(obj)),
        )
        const err = error instanceof Error ? error : new Error(String(error))
        if (axios.isAxiosError(err)) {
          const errResp = err.response
          console.log({
            name: err.name,
            message: err.message,
            respData: errResp.data,
            respStatus: errResp.status,
            respStatusText: errResp.statusText,
          })
        } else {
          console.error(`[${err.name}] ${err.message}`, err)
        }
      }
    },
    [endpoint, getItemId, idKey, onRemoveItem, setState],
  )

  const updateItem = React.useCallback(
    async function onUpdateItem(item) {
      try {
        await axios.put(endpoint, { idKey, items: u.array(item) })
      } catch (error) {
        const err = error instanceof Error ? error : new Error(String(error))
        throw err
      }
    },
    [endpoint, idKey],
  )

  const setRef = React.useMemo(
    () => (el: HTMLFormElement) => {
      crudRef.current = { form, el }
      formRef.current = el
    },
    [form],
  )

  React.useEffect(
    () => void form.setValue('items', items),
    // eslint-disable-next-line
    [form.setValue, items],
  )

  return {
    defaultValue,
    addItem,
    removeItem,
    updateItem,
    form,
    formRef,
    setRef,
  }
}

export default useCRUDForm
