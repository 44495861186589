import React from 'react'
import { useMediaQuery } from '@chakra-ui/react'

function useDeviceSizes() {
  const [isMobile, isTablet, isDesktop, isWidescreen] = useMediaQuery([
    '(max-width: 390px)',
    '(max-width: 640px)',
    '(max-width: 960px)',
    '(min-width: 1280px)',
  ])

  return [isMobile, isTablet, isDesktop, isWidescreen]
}

export default useDeviceSizes
