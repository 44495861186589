import type { LiteralUnion } from 'type-fest'
import * as u from '@jsmanifest/utils'
import { css } from '@emotion/react'
import pick from 'lodash/pick'
import React from 'react'
import type { BoxProps, InputProps } from '@chakra-ui/react'
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import type {
  ArrayPath,
  Control,
  FieldArray,
  FieldValues,
  Path,
  UnpackNestedValue,
} from 'react-hook-form'
import { useFieldArray } from 'react-hook-form'
import Svg from 'components/svg'
import useCRUDFormCtx from '../useCRUDFormCtx'
import InputField from '../InputField'
import type { Notes } from '../crudFormTypes'

export interface NotesFieldProps<
  K extends keyof FieldValues,
  S extends FieldValues & Record<K, Notes.Item[]>,
  N extends ArrayPath<S>,
> {
  control: Control<any>
  name: N
  boxProps?: BoxProps
  children?: (opts: {
    add: (value: any) => void
    remove: (index: number) => void
    update: (index: number, value: any) => void
    pending: string
    setPending: React.Dispatch<React.SetStateAction<string>>
  }) => React.ReactNode
  helperText?: string
  idKey?: LiteralUnion<'id', string>
  label?: string
  inputProps?: InputProps
  pendingInputProps?: InputProps
  placeholder?: string
  value?: Notes.Item[]
  onChange?: (...args: any[]) => any
}

function createItem<S, N extends ArrayPath<S>>(item: unknown) {
  return (u.isStr(item) ? { value: item } : item) as Partial<
    UnpackNestedValue<FieldArray<S, N>>
  >
}

function NotesField<
  K extends keyof FieldValues,
  S extends FieldValues & Record<K, Notes.Item[]>,
  N extends ArrayPath<S>,
>({
  children,
  control,
  boxProps,
  idKey = 'id',
  helperText,
  label,
  inputProps,
  name: fieldName,
  pendingInputProps,
  placeholder,
}: NotesFieldProps<K, S, N>) {
  const [pending, setPending] = React.useState('')
  const { styles: ctxStyles } = useCRUDFormCtx()

  const { fields, append, remove, update } = useFieldArray({
    name: fieldName,
    keyName: idKey,
    control,
  })

  const addItem = React.useCallback(
    (value: string) => {
      if (value) {
        append(createItem(value), { shouldFocus: false })
        setPending('')
      }
    },
    [append, setPending],
  )

  const onKeyPress = React.useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Enter') {
        evt.preventDefault()
        addItem(pending)
      }
    },
    [addItem, pending],
  )

  return (
    <Box className="notes-field" {...boxProps}>
      {fields?.map?.((field, index) => {
        const name = `${fieldName}.${index}.value` as Path<Partial<S>>
        return (
          <InputGroup key={field.id} overflow="hidden" alignItems="center">
            <InputLeftElement
              position="relative"
              pointerEvents="none"
              m={0}
              p={0}
              width={10}
            >
              <Text color="blue.200" fontWeight={700} fontSize="xs">
                #{index}
              </Text>
            </InputLeftElement>
            <InputField
              {...inputProps}
              className="notes-field-input"
              name={name}
              onKeyPress={(evt) => evt.key === 'Enter' && evt.preventDefault()}
              control={control}
              {...pick(ctxStyles?.input, ['borderRadius', 'focusBorderColor'])}
            />
            <InputRightElement
              title="Remove note"
              cursor="pointer"
              fontSize="sm"
              onClick={() => remove(index)}
              css={css({
                transition: 'opacity 0.3s ease',
                ':hover': {
                  opacity: 0.6,
                },
              })}
            >
              <Svg icon="remove" />
            </InputRightElement>
          </InputGroup>
        )
      }) || null}
      <Box display="flex" alignItems="center" {...ctxStyles?.field}>
        <Input
          name="pending"
          value={pending}
          onChange={(evt) => setPending(evt.target.value)}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          {...pick(ctxStyles?.input, [
            'bg',
            'borderRadius',
            'borderColor',
            'colorScheme',
            'focusBorderColor',
            'fontSize',
            'size',
          ])}
          {...pendingInputProps}
        />
        <span style={{ width: 15 }} />
        <Input
          type="submit"
          onClick={() => addItem(pending)}
          placeholder="Submit"
          width={200}
          {...ctxStyles?.input}
          bg="blackAlpha.400"
        />
      </Box>
      {children?.({
        add: append,
        remove,
        update,
        pending,
        setPending,
      }) || null}
    </Box>
  )
}

export default NotesField
