import React from 'react'
import * as u from '@jsmanifest/utils'
import { useToast } from '@chakra-ui/react'
import CRUDPage from 'components/CRUDPage'
import { titlesState } from './store'

function itemToString(item: Record<string, any> | string) {
  return (u.isStr(item) ? item : item?.['title']) || ''
}

function softResetInputValue(inputValue: string) {
  return inputValue.includes('-')
    ? inputValue.substring(0, inputValue.indexOf('-') + 1)
    : inputValue
}

function AdminTitlesPage() {
  const toast = useToast()

  return (
    <CRUDPage
      endpoint="/api/titles"
      fetchKey="fetch-titles"
      getAddItemPostBody={(item) => ({ title: itemToString(item) })}
      getRemoveItemParams={(item) => ({
        title: itemToString(item),
        ...(u.isStr(item) ? undefined : { id: item['id'] }),
      })}
      getInputValueAfterSubmit={softResetInputValue}
      getInputValueAfterRemove={softResetInputValue}
      getInputValueAfterRejectRemove={(inputValue) => inputValue}
      idKey="title"
      limit={150}
      initialState={titlesState}
      isEqual={(arg1, arg2) => itemToString(arg1) === itemToString(arg2)}
      itemToString={itemToString}
      onMount={(state) => console.log(`[Titles] State`, state)}
      onError={({ name, message, response }) => {
        toast({
          title: name,
          description: `${message}${response ? JSON.stringify(response) : ''}`,
          position: 'bottom',
          status: 'error',
        })
      }}
    />
  )
}

export default AdminTitlesPage
