import React from 'react'
import type { RouteComponentProps } from '@reach/router'
import type { GridItemProps } from '@chakra-ui/react'
import {
  Box,
  GridItem,
  Heading,
  CircularProgress,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { eventsState, titlesState } from './store'

function Panel({
  loading = false,
  label,
  children,
  ...rest
}: GridItemProps & { label?: string; loading?: boolean }) {
  return (
    <GridItem
      bg="blackAlpha.400"
      display="flex"
      flexDir="column"
      justifyContent="center"
      p={3}
      {...rest}
    >
      <Heading size="md">{label || null}</Heading>
      {loading ? <CircularProgress isIndeterminate /> : <Box>{children}</Box>}
    </GridItem>
  )
}

function AdminPortal({ location, navigate }: RouteComponentProps) {
  const events = useRecoilValue(eventsState)
  const titles = useRecoilValue(titlesState)

  return (
    <>
      <Box>
        <SimpleGrid columns={3} spacing={2}>
          <Panel loading={events.fetching} label="Events">
            <Text fontSize="md">{events.items.length} events</Text>
          </Panel>
          <Panel loading={titles.fetching} label="Titles">
            <Text fontSize="md">{titles.items.length} titles</Text>
          </Panel>
        </SimpleGrid>
      </Box>
    </>
  )
}

export default AdminPortal
