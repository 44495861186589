import * as u from '@jsmanifest/utils'
import React from 'react'
import produce, { Draft } from 'immer'
import type { InputProps } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import type { BoxProps } from 'components/Box'
import Box from 'components/Box'
import type { Notes } from '../types'

export interface NotesInputProps {
  boxProps?: BoxProps
  inputProps?: InputProps
  onChange?: (evt: React.ChangeEvent<any>, state: Notes.InputTextObject) => void
  add?: (evt: React.ChangeEvent<HTMLInputElement>, item: Notes.Item) => void
  remove?: (evt: React.ChangeEvent<HTMLInputElement>, item: Notes.Item) => void
  update?: (evt: React.ChangeEvent<HTMLInputElement>, item: Notes.Item) => void
  value?: Notes.InputObject[]
}

function NotesInput({
  boxProps,
  inputProps,
  value = [],
  onChange: onChangeProp,
  add = () => {},
  remove = () => {},
  update = () => {},
}: React.PropsWithChildren<NotesInputProps>) {
  const [pending, setPending] = React.useState({ value: '', index: 0 })

  const onChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setPending(produce((draft) => void (draft.value = evt.target.value)))
    },
    [],
  )

  const addNote = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const stateItem = {
        _name: u.getRandomKey(),
        value: evt.target.value,
      }
      const len = value.length
      stateItem.index = len ? len - 1 : 0
      add?.(evt, stateItem)
    },
    [value],
  )

  return (
    <Box {...boxProps}>
      {value.map((note) => (
        <Input
          key={note.value}
          {...inputProps}
          value={note.value}
          onChange={onChange}
        />
      ))}
      <Box as="form" onSubmit={addNote}>
        <Input value={pending.value} onChange={onChange} />
      </Box>
    </Box>
  )
}

export default React.memo(NotesInput, (p, np) => p.value === np.value)
