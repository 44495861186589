import React from 'react'
import type { RouteComponentProps } from '@reach/router'
import type { EventsState } from './store'
import { eventsState } from './store'
import type { Api } from 'types'
import useCommonLoadData from 'hooks/useCommonLoadData'

export interface PortalProps extends RouteComponentProps<any> {
  shifted: boolean
}

function DataLoader() {
  return null
}

export default DataLoader
