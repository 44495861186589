import React from 'react'
import * as u from '@jsmanifest/utils'

interface RenderProp<T = any> {
  (props?: T): React.ReactNode
}

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<A extends {} | null>() {
  const ctx = React.createContext<A | undefined>(undefined)

  function useCtx() {
    const c = React.useContext(ctx)

    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value')
    }

    return c
  }
  return [useCtx, ctx.Provider] as const // 'as const' makes TypeScript infer a tuple
}

/**
 * Helper to handle rendering a render prop function or to return its node
 * @param { function } render - Render prop or ReactNode
 * @param { object? } options
 * @param { React.ElementType<any>? } options.container
 * @param { object? } options.props
 */
export function handleRenderProp<T = any>(
  render: React.ReactNode | RenderProp,
  options?: { container?: React.ElementType<any>; props?: T },
) {
  if (typeof render === 'function') {
    if (options && typeof options === 'object') {
      const Container = options.container
      const props = options.props
      return Container ? <Container>{render(props)}</Container> : render(props)
    }
    return render()
  }
  return render
}

/**
 * Combine multiple React refs into a single ref function.
 * This is used mostly when you need to allow consumers forward refs to
 * internal components
 *
 * @param refs refs to assign to value to
 */
export function mergeRefs<T>(
  ...refs: (
    | React.MutableRefObject<T>
    | React.Ref<T>
    | React.RefObject<T>
    | undefined
  )[]
) {
  return (value: T | null) => {
    refs.forEach((ref) => {
      if (!ref) return
      if (u.isFnc(ref)) return ref(value)
      try {
        // @ts-ignore
        ref.current = value
      } catch (error) {
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`)
      }
    })
  }
}
