import React from 'react'
import axios from 'axios'
import type { Draft } from 'immer'
import produce from 'immer'
import type { ControllerStateAndHelpers } from 'downshift'
import type { RouteComponentProps } from '@reach/router'
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Input,
  InputLeftElement,
  InputGroup,
  List,
  Tab,
  TabList,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react'
import { InputField, NotesField, CRUDFormProvider } from 'components/CRUDForm'
import AutoCompleteInput from 'components/AutoCompleteInput'
import useCommonLoadData from 'hooks/useCommonLoadData'
import useCRUDForm from 'components/CRUDForm/useCRUDForm'
import Svg from 'components/svg'
import type { Api } from 'types'
import type { FieldObjectBase } from 'components/CRUDForm'
import type { Notes } from 'components/CRUDPage/types'
import type { VolunteerState } from './store'
import {
  volunteerState as volunteerRecoilState,
  volunteerAppliedState as volunteerAppliedRecoilState,
} from './store'

export type VolunteerItem = VolunteerState['items'][number]

export interface VolunteerFormValues {
  name: string
  url: string
  thumbnail: string
  notes: Notes.Item[]
}

function itemToString(item: VolunteerItem & { id: string }) {
  return item?.name
}

const baseEndpoint = '/api/volunteer'

function AdminVolunteerPage(props: RouteComponentProps) {
  const [tab, setTab] = React.useState<Api.VolunteerDataKind>('profiles')
  const [selectedIds, setSelectedIds] = React.useState([] as string[])
  const inputRef = React.useRef<HTMLInputElement>()

  const toast = useToast()

  const fields = React.useMemo(
    () =>
      [
        { name: 'description', placeholder: 'Description' },
        { name: 'url', placeholder: 'Url' },
        { name: 'thumbnail', placeholder: 'Thumbnail' },
        ...(tab === 'applied'
          ? [
              { name: 'date', type: 'date' },
              { name: 'time', type: 'time' },
            ]
          : []),
        {
          name: 'notes',
          mode: 'notes',
          placeholder: 'Add note(s)',
          inputProps: { placeholder: 'Note' },
        },
      ] as FieldObjectBase<'name'>[],
    [tab],
  )

  const [volunteerProfilesState, setVolunteerProfilesState] = useCommonLoadData(
    volunteerRecoilState,
    { key: 'volunteer-profiles', endpoint: `${baseEndpoint}/profiles` },
  )

  const [volunteerAppliedState, setVolunteerAppliedState] = useCommonLoadData(
    volunteerAppliedRecoilState,
    { key: 'volunteer-applied', endpoint: `${baseEndpoint}/applied` },
  )

  const stateMap = React.useMemo(
    () => ({
      applied: {
        state: volunteerAppliedState,
        setState: setVolunteerAppliedState,
      },
      profiles: {
        state: volunteerProfilesState,
        setState: setVolunteerProfilesState,
      },
    }),
    [
      volunteerAppliedState,
      volunteerProfilesState,
      setVolunteerAppliedState,
      setVolunteerProfilesState,
    ],
  )

  const { addItem, removeItem, updateItem, form } = useCRUDForm({
    endpoint: `${baseEndpoint}/${tab}`,
    fields,
    idKey: 'name' as const,
    initialValues: {
      items: stateMap[tab].state.items,
    },
    onRemoveItem: ({ getItemId, items }) => {
      setSelectedIds(
        produce((draft) =>
          items.forEach(
            (item) =>
              draft.includes(getItemId(item)) &&
              draft.splice(draft.indexOf(getItemId(item)), 1),
          ),
        ),
      )
    },
    setState: stateMap[tab].setState,
  })

  const searchQuery = form.watch('formValues.name')

  const onSelect = React.useCallback(
    (item: any, stateAndHelpers: ControllerStateAndHelpers<any>) => {
      stateAndHelpers.setState({ inputValue: searchQuery })
      console.log(`[onSelect] Item`, item)

      if (item?.name) {
        setSelectedIds(
          produce((draft: Draft<string[]>) => {
            if (draft.includes(item.name))
              draft.splice(draft.indexOf(item.name), 1)
            else draft.push(item.name)
          }),
        )
      }
    },
    [setSelectedIds, searchQuery],
  )

  const onRemoveSelected = React.useCallback(() => {
    removeItem(selectedIds.map((id) => ({ name: id })))
  }, [removeItem, selectedIds])

  const onSelectAll = React.useCallback(() => {
    setSelectedIds((prevItems) =>
      prevItems.length === stateMap[tab].state.items.length
        ? []
        : stateMap[tab].state.items.map((item) => item.name),
    )
  }, [stateMap, setSelectedIds, tab])

  const onSubmit = React.useCallback(
    async (values: {
      formValues: VolunteerFormValues
      items: VolunteerItem[]
    }) => {
      try {
        const item = values.formValues
        console.log(`[onSubmit] Item`, item)
        if (!item.name) {
          toast.closeAll()
          return toast({ title: 'Name is empty', status: 'error' })
        }
        await addItem(item)
      } catch (error) {
        const err = error instanceof Error ? error : new Error(String(error))
        if (axios.isAxiosError(err)) {
          const errResp = err.response
          console.log({
            name: err.name,
            message: err.message,
            respData: errResp?.data,
            respStatus: errResp?.status,
            respStatusText: errResp?.statusText,
          })
        } else {
          console.error(`[${err.name}] ${err.message}`, err)
        }
      }
    },
    [addItem, toast],
  )

  const ctxStyles = {
    field: {
      my: 1,
    },
    input: {
      borderRadius: 4,
      borderColor: 'whiteAlpha.700',
      colorScheme: 'blackAlpha',
      focusBorderColor: 'cyan.400',
      fontSize: 'xs',
      size: 'xs',
    },
  }

  return (
    <CRUDFormProvider value={{ styles: ctxStyles }}>
      <Box w="full">
        <Heading size="md">Volunteer Profiles</Heading>
        <Tabs my={1}>
          <TabList>
            <Tab onClick={() => tab !== 'profiles' && setTab('profiles')}>
              Profiles
            </Tab>
            <Tab onClick={() => tab !== 'applied' && setTab('applied')}>
              Applied
            </Tab>
          </TabList>
          <Box my={5}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <AutoCompleteInput
                descriptionKey="description"
                mode="selection"
                idKey="name"
                items={stateMap[tab].state.items}
                itemToString={itemToString}
                loading={stateMap[tab].state.fetching}
                onSelect={onSelect}
                selectedIds={selectedIds}
                value={searchQuery}
                isOpen
              >
                {({ getRootProps, getInputProps, getMenuProps, listItems }) => {
                  const totalListItems = listItems?.length || 0
                  const totalTitles = stateMap[tab].state.items.length
                  const ratio = `${totalListItems} / ${totalTitles}`
                  return (
                    <Box>
                      <Box display="flex">
                        <Text>{ratio}</Text>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        my="6px"
                        {...getRootProps(
                          { refKey: 'ref' },
                          { suppressRefError: true },
                        )}
                      >
                        <InputGroup alignItems="center" w="full">
                          <InputLeftElement left={0} onClick={onSelectAll}>
                            <Svg
                              stroke="green"
                              icon="checkboxChecked"
                              title="Select all"
                            />
                          </InputLeftElement>
                          <Input
                            ref={inputRef}
                            placeholder={'Name'}
                            variant="filled"
                            {...getInputProps(form.register('formValues.name'))}
                          />
                        </InputGroup>
                        <span style={{ width: 15 }} />
                        <ButtonGroup>
                          <Button
                            colorScheme="blackAlpha"
                            variant="solid"
                            onClick={onRemoveSelected}
                            isDisabled={!selectedIds.length}
                          >
                            Delete
                          </Button>
                          <Button
                            type="submit"
                            colorScheme="blackAlpha"
                            variant="solid"
                          >
                            Save
                          </Button>
                        </ButtonGroup>
                      </Box>
                      {fields.map(({ mode, name, ...fieldObject }, index) => (
                        <React.Fragment key={`${fieldObject.name}${index}`}>
                          {mode === 'notes' ? (
                            <NotesField
                              control={form.control}
                              name={`formValues.${name}`}
                              {...fieldObject}
                            />
                          ) : (
                            <InputField
                              control={form.control}
                              name={`formValues.${name}`}
                              {...ctxStyles.input}
                              {...fieldObject}
                            />
                          )}
                        </React.Fragment>
                      ))}
                      <List
                        {...getMenuProps({
                          refKey: 'ref',
                          style: { listStyle: 'none', marginTop: 20 },
                        })}
                      >
                        {listItems}
                      </List>
                    </Box>
                  )
                }}
              </AutoCompleteInput>
            </form>
          </Box>
        </Tabs>
      </Box>
    </CRUDFormProvider>
  )
}

export default AdminVolunteerPage
