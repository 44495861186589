import React from 'react'
import produce from 'immer'
import type { SecondaryField } from '../types'

function getFieldValue(
  descriptor: SecondaryField.Descriptor,
  value?: SecondaryField.Props['value'],
) {
  switch (descriptor.mode) {
    case 'notes':
      return value
    default:
      return value
  }
}

function getName() {}

function useSecondaryFields({
  initialValues = {},
  descriptors,
}: {
  initialValues?: Record<string, SecondaryField.Props['value']>
  descriptors?: SecondaryField.Descriptor[]
} = {}) {
  const [values, setValues] = React.useState(() => {
    const _state_ = {} as Record<string, SecondaryField.Props['value']>

    descriptors.forEach((descriptor) => {
      if (descriptor.name in initialValues) {
        _state_[descriptor.name] = getFieldValue(
          descriptor,
          initialValues[descriptor.name],
        )
      } else {
        _state_[descriptor.name] = getFieldValue(descriptor)
      }
    })

    return _state_
  })

  const onChange = React.useMemo(() => {
    return (
      evt: any,
      descriptor: SecondaryField.Descriptor,
      field: SecondaryField.Props,
    ) => {
      setValues(
        produce((draft) => {
          if (descriptor.mode === 'notes') {
            draft[descriptor.name] = field.value
          } else {
            draft[descriptor.name] = evt.target.value
          }
        }),
      )
    }
  }, [])

  return {
    values,
    onChange,
  }
}

export default useSecondaryFields
